import React from 'react';

const Integrations = () => {
    const handleCopyClick = () => {
        navigator.clipboard.writeText('<script src="https://js.loveitai.com/v3?ref=a8a8bb8d"></script>');
    };

    const Card = ({ title, children }) => (
        <div className="bg-white rounded-lg border border-slate-200 shadow-sm">
            <div className="border-b border-slate-200 px-6 py-4">
                <h2 className="text-xl font-semibold">{title}</h2>
            </div>
            <div className="px-6 py-4">
                {children}
            </div>
        </div>
    );

    const Button = ({ children, className, onClick }) => (
        <button
            onClick={onClick}
            className={`inline-flex items-center justify-center px-4 py-2 rounded-md text-sm font-medium transition-colors ${className}`}
        >
            {children}
        </button>
    );
    const isDev = window.location.hostname === 'localhost' || window.location.hostname.includes('ngrok');
    const redirectUri = isDev ? "https://0d78-2601-646-8700-e790-549f-7366-7b50-1af7.ngrok-free.app/auth/callback" : "https://www.loveitai.com/auth/callback"
    const clientId = isDev ? "1662640057917236" : "1207036460331201"
    const handleLogin = () => {
        const instagramAuthUrl = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish`;
        window.location.href = instagramAuthUrl;
    };


    return (
        <div className="mt-20 w-full max-w-3xl mx-auto space-y-6 p-4">
            <h1 className="text-3xl font-bold mb-6 text-center">Integrations</h1>

            {/* Social Media Section */}
            <Card title="Social Media">
                <div className="mb-3">First go into your Instagram mobile app and go settings into the "App website permissions" then into the "App and websites" section. You should see an invite from LoveItAI. Accept the invite then use the button below to connect your Instagram.  </div>
                <Button onClick={handleLogin}
                    className="bg-gradient-to-r from-pink-500 via-purple-500 to-orange-500 text-white hover:opacity-90"
                >
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png"
                        alt="Instagram"
                        className="w-5 h-5 mr-2"
                    />
                    Connect to Instagram
                </Button>
            </Card>

            {/* Website Section */}
            <Card title="Website">
                <p>Coming soon!</p>
          {/*      <p className="mb-3">Copy the following javascript snippet into your website</p>*/}
          {/*      <div className="relative">*/}
          {/*<pre className="bg-slate-900 text-slate-50 rounded-lg p-4 overflow-x-auto">*/}
          {/*  <code>{'<script src="https://js.loveitai.com/v3?ref=a8a8bb8d"></script>'}</code>*/}
          {/*  <button*/}
          {/*      onClick={handleCopyClick}*/}
          {/*      className="absolute top-2 right-2 p-1 text-slate-400 hover:text-slate-100 rounded-md"*/}
          {/*  >*/}
          {/*    📋*/}
          {/*  </button>*/}
          {/*</pre>*/}
          {/*      </div>*/}
             </Card>

            {/* Email Section */}
            <Card title="Email">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                    Coming soon!
                    {/*<Button*/}
                    {/*    className="bg-white text-slate-900 border border-slate-200 hover:bg-slate-100 hover:border-slate-300"*/}
                    {/*>*/}

                    {/*    Connect to Mailchimp*/}
                    {/*</Button>*/}
                    {/*<Button*/}
                    {/*    className="bg-white text-slate-900 border border-slate-200 hover:bg-slate-100 hover:border-slate-300"*/}
                    {/*>*/}

                    {/*    Connect to Klaviyo*/}
                    {/*</Button>*/}
                    {/*<Button*/}
                    {/*    className="bg-white text-slate-900 border border-slate-200 hover:bg-slate-100 hover:border-slate-300"*/}
                    {/*>*/}

                    {/*    Connect to Gmail*/}
                    {/*</Button>*/}
                </div>
            </Card>
        </div>
    );
};

export default Integrations;