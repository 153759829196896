import React, { useEffect } from 'react';

const VegasJackDemo = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `/loveitai.js?v=${Date.now()}`; // Add timestamp to prevent caching
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return <div />;
};

export default VegasJackDemo;