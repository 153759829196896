import { FooterData } from '../../data/FooterSectionData';
import CTAButtons from '../shared/CTAButtons';
import React from 'react';
const FooterSection = () => {
  return (
    <div className="pt-[270px] desktop:pl-[153px]  desktop:pr-[200px] dekstop:pr-[231px] pl-[47px] flex items-center justify-start bg-[#18181B] pb-[50px] relative">
      <div className="desktop:py-[133px] p-[27px] min-w-[322px] mx-auto  desktop:w-[1296px] flex flex-col gap-7 desktop:h-[433px] items-center justify-center rounded-[10px] bg-footer-complex-gradient absolute top-[-216.5px] left-[50%] translate-x-[-50%]">
        <div className="flex flex-col gap-[10px] items-center justify-center">
          <h2 className="text-white titleSections text-4xl mobile:text-[28px] font-bold leading-[44px] max-w-[636px] tracking-[-1px] text-center mx-auto">
            Get Started with LoveItAI
          </h2>
          <p className="max-w-[550px] text-lg mobile:text-base font-normal leading-[30px] text-white opacity-70 text-center">
            See what LoveItAI's shopper copilot experience can do for you.
          </p>
        </div>
        <CTAButtons />
      </div>
      <div className="flex items-end justify-between w-full">
        <div className="flex gap-5 desktop:flex-col flex-col-reverse">
          <div dangerouslySetInnerHTML={{ __html: FooterData.logo }}></div>
          {FooterData.links.map((link, i) => (
            <p
              key={i}
              className="text-white font-normal linksSectionsFooter text-sm">
              {link}
            </p>
          ))}
        </div>
        {FooterData.quickLinks.map((link, i) => (
          <div key={i} className="desktop:flex flex-col gap-5 hidden">
            <p className="text-base font-bold text-white titleSectionsFooter">
              {link.title}
            </p>
            {link.links.map((link, i) => (
              <p
                key={i}
                className="text-white font-normal text-sm linksSectionsFooter">
                {link}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FooterSection;
