import React from 'react';
import CTAButtons from '../shared/CTAButtons';

const TitleSection = () => {
    return (
        <div className="flex flex-col gap-[2.4375rem] justify-center items-center mt-[9.75rem] mobile:mt-[103px]">
            <div className="flex flex-col items-center justify-center gap-6">
                <h1 className="font-bold titleSections desktop:w-[1286px] text-center desktop:text-[74px] tablet:text-[44px] mobile:text-[28px] tablet:max-w-[782px] mobile:max-w-[312px] leading-[80px] mobile:leading-[120%] text-offBlack">
                    Shopper Copilot AI That Transforms{' '}
                    <span className="bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
            Browsers
          </span>{' '}
                    into{' '}
                    <span className="bg-gradient-to-r from-red-500 to-orange-500 bg-clip-text text-transparent">
            Buyers
          </span>
                </h1>
                <p className="font-normal text-[20px] mobile:text-[13px] mobile:leading-[150%] mobile:max-w-[255px] leading-8 text-offBlack opacity-75 max-w-[42.875rem] text-center">
                    Personalized, omnichannel shopping experiences that keep customers engaged at every step, from website to social, SMS, email, and beyond.
                </p>
            </div>
            <CTAButtons />
        </div>
    );
};

export default TitleSection;