export const contentData = [
  {
    id: 1,
    img: './assets/secondItem.png',
    title: 'Understand Each Unique Customer Journey',
    intro:
      'LoveItAI gets to know and truly understand each shopper’s individual interests and needs by asking questions and using contextual and historical data.',
    isLeft: true,
  },
  {
    id: 2,
    img: './assets/firstItem.png',
    title: 'Highlight Why People Love your Brand',
    intro:
      'Make it easy for customers to see what makes your brand special and unique, helping build interest and motivation to buy.\n',
    isLeft: false,
  },
  {
    id: 3,
    img: './assets/fouItem.png',
    title: 'Give Personalized Recommendations & Offers',
    intro:
      'Reduce decision fatigue with recommendations tailored for them as well as special offers to maximize purchase conversion.\n',
    isLeft: true,
  },
  {
    id: 4,
    img: './assets/thirdItem.png',
    title: 'Follow up Across Every Channel and Touch Point',
    intro:
      'AI integrated into social media, SMS and email for post purchase engagement as well as abandoned cart reminders.',
    isLeft: false,
  },
];
