import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import GetStarted from "./GetStarted";
import InstagramSuccess from "./Manage/InstagramSuccess";
import InviteAccountCreation from './Manage/InviteAccountCreation';
import WaitlistSignup from './WaitlistSignup';
import WaitlistSignups from "./Admin/WaitlistSignups";
import { AuthContext, AuthProvider } from '../context/AuthContext';
import UserList from "./Admin/UserList";
import ConversationList from "./Admin/ConversationList";
import MessageList from "./Admin/MessageList";
import SignIn from "./SignIn";
import Setup from "./Manage/Setup";
import Playground from "./Manage/Playground";
import Integrations from "./Manage/Integrations";
import HomePage from '../Page/HomePage';
import DemoPage from '../Page/DemoPage';
import { initMixpanel } from './Analytics/mixpanel';
import PageViewTracker from './Analytics/PageViewTracker';
import PrivateRoute from './shared/PrivateRoute';
import AdminRoute from './shared/AdminRoute';
import ConsentForm from "./Content/ConsentForm";
import VegasJackDemo from "./Demos/VegasJackDemo";
import SakeDemoPage from "./Demos/SakeDemoPage";
import IframeDemo from "./Demos/IframeDemo";

// ScrollToTop component that wraps the routes
function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        initMixpanel();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}



const App = () => {
    const { user, logout } = useContext(AuthContext);

    return (
        <AuthProvider>
            <Router>
                <ScrollToTop />
                <PageViewTracker />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/demos" element={<DemoPage />} />
                    <Route path="/instagram-connect" element={<GetStarted/>} />
                    <Route path="/sms-consent" element={<ConsentForm/>} />
                    <Route path="/instagram-success" element={<InstagramSuccess/>} />
                    <Route path="/sake-demo" element={<SakeDemoPage/>}/>
                    <Route path="/vegas-jack-bets" element={<VegasJackDemo/>}/>
                    <Route path="/admin" element={<AdminRoute />}>
                        <Route path="" element={<UserList />} />
                        <Route path="users/:userId/conversations" element={<ConversationList />} />
                        <Route path="users/:userId/conversations/:conversationId/messages" element={<MessageList />} />
                        <Route path="waitlist-signups" element={<WaitlistSignups />} />
                    </Route>
                    <Route path="/mebio-pet" element={<IframeDemo url={"https://sklep.hippovet.pl/"}/>} />
                    <Route path="/kristoff-farms" element={<IframeDemo url={"https://www.kristoffarms.com/"}/>} />
                    <Route path="/pivot" element={<IframeDemo url={"https://www.thisispivotpdx.com/"}/>} />
                    <Route path="/join" element={<WaitlistSignup/>}/>
                    <Route path="/playground" element={<Playground/>}/>
                    <Route path="/integrations" element={<Integrations/>}/>
                    <Route path="/setup" element={<Setup/>}/>
                    <Route path="/get-started" element={<InviteAccountCreation/>} />

                    <Route path="/sign_in" element={<SignIn/>}/>
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;