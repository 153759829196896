import React, { useEffect } from 'react';

const SakeDemoPage = () => {
    useEffect(() => {
        // Dynamically load the external script
        const script = document.createElement('script');
        script.src = '/loveitai.js';
        script.async = true;
        document.body.appendChild(script);

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div style={{ fontFamily: 'Arial, sans-serif', textAlign: 'center', marginTop: '150px' }}>
            <h1 style={{ fontSize: '2rem', color: '#333' }}>Demo Page for Website Copilot Shopper AI</h1>
            <h2 style={{ fontSize: '1.5rem', color: '#555', marginTop: '20px' }}>Sake Experience</h2>
            <p style={{ fontSize: '1rem', color: '#777', marginTop: '10px' }}>
                Explore how AI can enhance your online sake shopping experience.
            </p>
        </div>
    );
};

export default SakeDemoPage;
