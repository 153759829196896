import React, { useState, useEffect, useRef } from 'react';
import axios from '../AxiosConfig';
import { createConsumer } from "@rails/actioncable";

const Playground = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [instructions, setInstructions] = useState('');
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isConnected, setIsConnected] = useState(false);
    const [isTyping, setIsTyping] = useState(false);

    const channelRef = useRef(null);
    const messagesEndRef = useRef(null);
    const jwtToken = localStorage.getItem('token');
    const clientIdRef = useRef(`playground_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const setupSubscription = (clientId) => {
        const cable = createConsumer(`${window.location.protocol}//${window.location.host}/cable`);

        console.log('Client ID used in subscription:', clientId);

        channelRef.current = cable.subscriptions.create(
            {
                channel: "PlaygroundChannel",
                client_id: clientId,
                token: jwtToken
            },
            {
                connected() {
                    console.log('Connected to Playground channel');
                    setIsConnected(true);
                },
                disconnected() {
                    console.log('Disconnected from Playground channel');
                    setIsConnected(false);
                },
                received(data) {
                    console.log('Received:', data);
                    setIsTyping(false);

                    if (data.type === 'history') {
                        setMessages(data.messages.map(msg => ({
                            ...msg,
                            id: msg.message_id
                        })));
                    } else {
                        setMessages((prev) => [...prev, {
                            text: data.message,
                            role: data.role,
                            timestamp: data.timestamp,
                            error: data.error,
                            id: data.message_id || Date.now()
                        }]);
                    }
                }
            }
        );
    };

    useEffect(() => {
        const fetchInstructions = async () => {
            try {
                const response = await axios.get('/api/assistants/get_instructions');
                setInstructions(response.data.instructions || '');
            } catch (err) {
                console.error('Failed to fetch instructions:', err);
                setError('Failed to load AI instructions. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchInstructions();
        setupSubscription(clientIdRef.current);

        return () => {
            if (channelRef.current) {
                channelRef.current.unsubscribe();
            }
        };
    }, [jwtToken]);

    const handleInstructionSave = async () => {
        try {
            setIsEditing(false);
            const response = await axios.put('/api/assistants/update_instructions', { instructions });
            console.log('Instructions updated successfully:', response.data);
        } catch (err) {
            console.error('Failed to update instructions:', err);
            setError('Failed to update AI instructions. Please try again later.');
        }
    };

    const startNewConversation = () => {
        setMessages([]);
        if (channelRef.current) {
            channelRef.current.unsubscribe();
        }
        const newClientId = `playground_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
        clientIdRef.current = newClientId;
        setupSubscription(newClientId);
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (!newMessage.trim() || !channelRef.current) return;

        const newMsg = {
            id: Date.now(),
            role: 'user',
            text: newMessage.trim(),
            timestamp: new Date()
        };

        setMessages((prev) => [...prev, newMsg]);
        setNewMessage('');
        setIsTyping(true);

        try {
            channelRef.current.perform('speak', { client_id: clientIdRef.current, message: newMessage.trim() });
        } catch (err) {
            console.error('Error sending message:', err);
            setIsTyping(false);
        }
    };

    if (isLoading) {
        return <div className="text-center mt-10 text-gray-600">Loading...</div>;
    }

    return (
        <div className="min-h-screen bg-gray-50 p-6">
            <div className="max-w-7xl mx-auto mb-12 relative">
                <div className="absolute right-0 top-1/2 -translate-y-1/2">
                    <button
                        className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-colors"
                        onClick={() => {
                            window.location.href = '/integrations';
                        }}
                    >
                        Connect AI
                    </button>
                </div>
                <h1 className="text-4xl font-bold text-gray-900 text-center">
                    Your AI Playground
                </h1>
            </div>

            <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-white rounded-xl shadow-lg p-6">
                    <h2 className="text-xl font-semibold text-gray-900 mb-4">
                        AI Instructions
                    </h2>

                    {error && <p className="text-red-500">{error}</p>}

                    {isEditing ? (
                        <div className="space-y-4">
                            <textarea
                                value={instructions}
                                onChange={(e) => setInstructions(e.target.value)}
                                className="w-full h-[500px] p-4 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                            />
                            <button
                                onClick={handleInstructionSave}
                                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                                Save
                            </button>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            <p className="min-h-[500px] p-4 border border-gray-200 rounded-md text-gray-600 whitespace-pre-wrap">
                                {instructions || 'No instructions available.'}
                            </p>
                            <button
                                onClick={() => setIsEditing(true)}
                                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            >
                                Edit
                            </button>
                        </div>
                    )}
                </div>

                <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col">
                    <div className="mb-6">
                        <button
                            onClick={startNewConversation}
                            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                            Start new conversation
                        </button>
                    </div>

                    <div className="flex-1 border border-gray-200 rounded-lg p-4 bg-gray-50 flex flex-col">
                        <div className="flex-1 overflow-y-auto space-y-4">
                            {messages.map((msg) => (
                                <div
                                    key={msg.id}
                                    className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}
                                >
                                    <div
                                        className={`max-w-[80%] p-3 rounded-lg ${
                                            msg.role === 'user'
                                                ? 'bg-blue-500 text-white'
                                                : msg.error
                                                    ? 'bg-red-100 text-red-700'
                                                    : 'bg-gray-100 text-gray-900'
                                        }`}
                                    >
                                        <div className="text-sm">{msg.text}</div>
                                        <div className="text-xs mt-1 opacity-75">
                                            {new Date(msg.timestamp).toLocaleTimeString()}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {isTyping && (
                                <div className="flex justify-start">
                                    <div className="bg-gray-100 p-3 rounded-lg flex space-x-2">
                                        <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
                                        <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
                                        <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
                                    </div>
                                </div>
                            )}
                            <div ref={messagesEndRef} />
                        </div>

                        <form onSubmit={handleSendMessage} className="flex gap-2 mt-4">
                            <input
                                type="text"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                placeholder="Type your message..."
                                className="flex-1 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                disabled={!isConnected}
                            />
                            <button
                                type="submit"
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                                disabled={!isConnected || isTyping || !newMessage.trim()}
                            >
                                {isTyping ? 'Sending...' : 'Send'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Playground;
