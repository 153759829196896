import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

const IframeDemo = ({ url }) => {
    useEffect(() => {
        // Dynamically load the external script
        const script = document.createElement('script');
        script.src = '/loveitai.js';
        script.async = true;
        document.body.appendChild(script);

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <iframe
            src={url}
            title="Full Window Iframe"
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                border: 'none',
                zIndex: 1000000,
            }}
            allowFullScreen
        ></iframe>
    );
};


IframeDemo.propTypes = { // Ensure the propTypes match the new name
    url: PropTypes.string.isRequired,
};

export default IframeDemo;
