import React, { useState } from 'react';

const ConsentForm = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [consent, setConsent] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate the form
        if (!phoneNumber.trim()) {
            setError('Phone number is required.');
            return;
        }

        if (!consent) {
            setError('You must agree to receive SMS messages.');
            return;
        }

        // Submit logic (e.g., send to API)
        console.log({
            phoneNumber,
            consent: true,
            timestamp: new Date().toISOString(),
        });

        // Mark as submitted
        setSubmitted(true);
        setError('');
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-50 px-4">
            <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full md:max-w-lg">
                <h1 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
                    Subscribe to Updates
                </h1>
                <p className="text-gray-600 mb-6 text-center">
                    Enter your phone number to receive exclusive updates, offers, and
                    news. By submitting, you agree to receive SMS messages from us.
                </p>

                {submitted ? (
                    <div className="text-green-600 font-semibold text-center">
                        Thank you! Your consent has been recorded.
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} className="space-y-4">
                        {/* Phone Number Input */}
                        <div>
                            <label
                                htmlFor="phoneNumber"
                                className="block text-gray-700 font-medium mb-1"
                            >
                                Phone Number
                            </label>
                            <input
                                type="tel"
                                id="phoneNumber"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="e.g., +1234567890"
                                className="w-full border border-gray-300 rounded-md p-3 focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>

                        {/* Consent Checkbox */}
                        <div className="flex items-start space-x-3">
                            <input
                                type="checkbox"
                                id="consent"
                                checked={consent}
                                onChange={(e) => setConsent(e.target.checked)}
                                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                required
                            />
                            <label htmlFor="consent" className="text-gray-700">
                                I agree to receive SMS messages and understand that I can opt
                                out at any time.
                            </label>
                        </div>

                        {/* Error Message */}
                        {error && <p className="text-red-600 text-sm">{error}</p>}

                        {/* Submit Button */}
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 transition"
                        >
                            Submit
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ConsentForm;
