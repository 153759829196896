import React, { useContext } from 'react';
import { quickLinks } from '../../data/heroSectionData';
import { AuthContext } from '../../context/AuthContext'; // Adjust the path as necessary

const Header = () => {
  const { user } = useContext(AuthContext);

  const handleGetStarted = () => {
    window.location.href = '/join';
  };

  const navigateTo = (path) => {
    window.location.href = path;
  };

  return (
      <div className="flex items-center justify-between mx-auto fixed bg-white w-full z-[999999] top-0 h-[5rem] shadow-lg">
        <div className="w-full desktop:px-[9.5rem] flex items-center justify-between mx-auto bg-white fixed">
          <div className="flex items-center gap-[3.59375rem] desktop:pl-0 pl-[29px]">
            <a href="/">
              <img
                  src="/assets/logoLoveitAi.png"
                  alt="LoveItAI Logo"
                  className="max-w-[137px]"
              />
            </a>
            <nav className="desktop:flex items-center gap-3 hidden">
              {quickLinks.map((link, i) => (
                  <a
                      href={`/${link.toLowerCase()}`}
                      key={i}
                      className="heeboText hover:text-blue-600 transition-colors"
                  >
                    {link}
                  </a>
              ))}
            </nav>
          </div>
          <div className="flex items-center gap-8">
            {!user ? (
                <button
                    onClick={handleGetStarted}
                    className="bg-background-button desktop:block hidden h-[56px] w-[167px] rounded-[4px] text-white hover:opacity-90 transition-opacity active:transform active:scale-95"
                >
                  Get Started Free
                </button>
            ) : (
                <div className="flex items-center gap-4">
                  <button
                      onClick={() => navigateTo('/playground')}
                      className="hover:underline transition-colors"
                  >
                    Playground
                  </button>
                  <button
                      onClick={() => navigateTo('/integrations')}
                      className="hover:underline transition-colors"
                  >
                    Integrations
                  </button>
                </div>
            )}
          </div>
        </div>
      </div>
  );
};

export default Header;
