// import FirstIcon from '../assets/icons/FirstIcon';
// import SecondIcon from '../assets/icons/secondIcon';
// import ThirdIcon from '../assets/icons/ThirdIcon';

export const BrandData = [
  {
    id: 1,
    icon: `<svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none">
        <path
          d="M39.9447 5.99231C39.8029 6.72142 39.7322 7.46255 39.7336 8.20529C39.7336 14.8691 45.1437 20.2713 51.8173 20.2713C52.5521 20.2677 53.2851 20.1971 54.0071 20.0605V43.9817C54.0071 54.0455 48.0708 59.9994 37.9923 59.9994H16.0412C5.9363 59.9994 0 54.0455 0 43.9817V22.0627C0 11.999 5.9363 5.99231 16.0412 5.99231H39.9447ZM40.3073 23.9775L39.9553 23.9994C39.3685 24.0838 38.8412 24.4022 38.4936 24.8816L31.2645 34.1814L23.0328 27.7005C22.5629 27.3344 21.965 27.1728 21.3743 27.2521C20.7835 27.3314 20.2496 27.645 19.8932 28.1221L11.0283 39.5558C10.7167 39.9442 10.549 40.4281 10.5534 40.9257L10.5612 41.2568C10.6471 42.127 11.2439 42.8788 12.0987 43.1486C13.0604 43.4522 14.106 43.0691 14.6429 42.2166L22.0567 32.627L30.2883 39.0816C30.7565 39.459 31.3583 39.6301 31.9554 39.5556C32.5525 39.4811 33.0936 39.1673 33.4543 38.6864L42.029 27.6215V27.5688C42.7631 26.5824 42.5758 25.1915 41.6069 24.4338C41.1379 24.0718 40.542 23.915 39.9553 23.9994L40.3073 23.9775Z"
          fill="#F23936"
        />
        <path
          opacity="0.4"
          d="M52.5054 15.002C56.6481 15.002 60.0064 11.6437 60.0064 7.50099C60.0064 3.35831 56.6481 0 52.5054 0C48.3627 0 45.0044 3.35831 45.0044 7.50099C45.0044 11.6437 48.3627 15.002 52.5054 15.002Z"
          fill="#F23936"
        />
      </svg>`,
    title: 'Engagement',
    intro:
      'Create high-quality, memorable shopping experiences that capture attention, build excitement and forge emotional connections with your brand.',
  },
  {
    id: 2,
    icon: `<svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none">
        <path
          d="M24.4583 10.666C24.611 10.977 24.712 11.3101 24.7572 11.6525L25.5925 24.0722L26.0071 30.3147C26.0114 30.9566 26.1121 31.5944 26.306 32.2076C26.8068 33.3971 28.0115 34.1532 29.3222 34.1004L49.294 32.7941C50.1588 32.7798 50.994 33.1033 51.6157 33.6933C52.1338 34.185 52.4683 34.8281 52.5737 35.5199L52.6091 35.94C51.7826 47.3841 43.3775 56.9294 31.9571 59.3934C20.5367 61.8574 8.82559 56.6523 3.18213 46.6041C1.55516 43.6849 0.538951 40.4762 0.193146 37.1664C0.0486857 36.1866 -0.0149235 35.1969 0.00293768 34.2071C-0.0148978 21.9376 8.72241 11.3303 20.953 8.77315C22.425 8.54394 23.8681 9.32321 24.4583 10.666Z"
          fill="#F23936"
        />
        <path
          opacity="0.4"
          d="M32.6102 0.00245756C46.2899 0.35048 57.7871 10.1874 60.0002 23.4369L59.9791 23.5346L59.9187 23.6768L59.9271 24.0671C59.8957 24.5841 59.6961 25.0816 59.3521 25.4835C58.9937 25.902 58.5041 26.1871 57.965 26.2977L57.6362 26.3428L34.5938 27.8358C33.8274 27.9114 33.0642 27.6643 32.4942 27.1559C32.0192 26.7322 31.7156 26.1603 31.6298 25.544L30.0832 2.53519C30.0563 2.45739 30.0563 2.37305 30.0832 2.29524C30.1043 1.66102 30.3835 1.06152 30.8583 0.630689C31.3332 0.199859 31.9641 -0.0264011 32.6102 0.00245756Z"
          fill="#FAAFAD"
        />
      </svg>`,
    title: 'Conversions',
    intro:
      "Deliver tailored recommendations and reduce friction to turn browsers into buyers, boosting conversion rates effortlessly.",
  },
  {
    id: 3,
    icon: ` <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="64"
        viewBox="0 0 60 64"
        fill="none">
        <path
          d="M56.7737 36.9963C53.7852 35.2663 52.7509 31.5353 54.4393 28.5753C54.9936 27.6196 55.7994 26.8251 56.7737 26.2736C58.2547 25.4452 59.3362 24.0769 59.7796 22.4706C60.223 20.8643 59.9918 19.1522 59.137 17.7122L57.0331 14.035C56.1826 12.5926 54.7777 11.5392 53.1285 11.1074C51.4793 10.6755 49.7215 10.9007 48.243 11.7333C46.7602 12.5672 44.998 12.7931 43.3441 12.3614C41.6902 11.9298 40.2801 10.8759 39.4241 9.43154C38.8638 8.47318 38.5661 7.39053 38.5595 6.28769C38.5672 4.62268 37.8935 3.0233 36.6874 1.84331C35.4813 0.663329 33.8422 0 32.1327 0H27.8961C24.342 0 21.4563 2.79799 21.4405 6.25962C21.4525 8.50471 20.2292 10.5842 18.2345 11.7093C16.2397 12.8344 13.7791 12.8328 11.7858 11.7052C10.2937 10.8388 8.50509 10.5975 6.82742 11.0364C5.14975 11.4754 3.72596 12.557 2.88044 14.035L0.863045 17.5719C-0.900227 20.5731 0.155538 24.3978 3.22628 26.1332C6.30381 27.879 7.34839 31.7225 5.5607 34.7227C5.01392 35.6766 4.20571 36.4638 3.22628 36.9963C1.74535 37.8247 0.663801 39.193 0.22041 40.7993C-0.222982 42.4056 0.00824586 44.1177 0.863045 45.5577L2.88044 49.1507C4.67289 52.1481 8.61904 53.1655 11.6993 51.4244C14.7829 49.7233 18.6974 50.7483 20.4894 53.7261C21.0598 54.6804 21.3582 55.7657 21.354 56.8699C21.354 58.5473 22.042 60.1552 23.2652 61.336C24.4883 62.5168 26.1452 63.1726 27.8673 63.1579H32.1039C35.6692 63.1579 38.5595 60.3425 38.5595 56.8699C38.5364 55.1953 39.2033 53.5814 40.4111 52.3892C41.6188 51.197 43.2668 50.526 44.9864 50.5261C46.1204 50.5193 47.2355 50.8102 48.2142 51.3682C51.2956 53.0856 55.2225 52.0573 57.0043 49.0665L59.137 45.4735C60.8588 42.4934 59.8066 38.719 56.7737 36.9963Z"
          fill="#F23936"
        />
        <path
          d="M30.0002 39.474C34.3603 39.474 37.8949 35.9394 37.8949 31.5793C37.8949 27.2192 34.3603 23.6846 30.0002 23.6846C25.6401 23.6846 22.1055 27.2192 22.1055 31.5793C22.1055 35.9394 25.6401 39.474 30.0002 39.474Z"
          fill="#FDDEDC"
        />
      </svg>`,
    title: 'Loyalty',
    intro:
      'Surprise customers with seamless, positive interactions that inspire word-of-mouth referrals and ensure customers return time and time again.\n',
  },
];
